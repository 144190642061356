// ant components
// import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// local components
import A4 from './components/A4';
import Container from './components/Container';
// import FirstPage from './components/FirstPage';
// import Logo from './components/Logo';
// import Overlay from './components/Overlay';
// import PrintContainer from './components/PrintContainer';
// import SubTitle from './components/SubTitle';
// import Title from './components/Title';

// local services
import printService from './services/print.service';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// uuid
import {v4} from 'uuid';

class Print extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    responsiveView: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    trigger: PropTypes.func.isRequired,
  };

  static defaultProps = {
    responsiveView: false,
  };

  contentId = v4();

  print = () => {
    const {responsiveView} = this.props;
    printService({domId: this.contentId, responsive: responsiveView});
  };

  render() {
    const {trigger, children} = this.props;

    return (
      <Fragment>
        {trigger({print: this.print})}
        <Container id={this.contentId}>
          {/* {!!this.props.title && (
            <FirstPage>
              <Logo />
              <Title>{this.props.title}</Title>
              {!!this.props.subtitle && <SubTitle>{this.props.subtitle}</SubTitle>}
              <div>{longDateFormatter()}</div>
            </FirstPage>
          )} */}
          <A4>{children}</A4>
          {/* <PrintContainer>
            <Overlay className="printOverlay">
              <Button type="primary" id="closePrintWindowButton">
                CLOSE
              </Button>
            </Overlay>
          </PrintContainer> */}
        </Container>
      </Fragment>
    );
  }
}

export default Print;
