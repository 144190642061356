const ATTRIBUTES_TO_COPY = [
  'app_reservations_per_hour',
  'minimum_deposit',
  'minimum_rental_rate',
  'requires_emissions',
  'requires_fhv',
  'requires_rental_insurance_id',
  'requires_rideshare_inspection',
  'requires_tlc',
  'state_change_fields',
  'tax_rate',
  'toll_service',
  'weekly_safetyscore_budget',
  'registration_pickup_window',
  'reservation_deposit_required',
];

export default ATTRIBUTES_TO_COPY;
