import {REDUX_SET, REDUX_RESET} from './constants';

const DEFAULT_STATE = {
  tab: 'payment-center',

  customer: null,
  loadingCustomer: true,
  cardsInited: false,

  activeRental: null,
  loadingActiveRental: true,
  spireon: null,
  driverReferral: null,

  saveCustomer: null,
  savingCustomer: false,

  notes: [],
  loadingNotes: true,

  cards: [],
  loadingCards: true,

  chargeID: 0,

  contract: null,
  contractVariables: null,

  paymentPlanBalance: null,

  loadingMvrViolationChoices: false,
  mvrViolationChoices: null,

  highlightTransactionId: null,
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case REDUX_SET:
      return {
        ...state,
        ...data,
      };

    case REDUX_RESET:
      return {...DEFAULT_STATE};

    default:
      return state;
  }
};
