// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local store
import vehicleTrackerStore from './store/vehicleTracker.store';

// date
import {parseISO, getUnixTime} from 'date-fns';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

class VehicleEventTrackerContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
    events: PropTypes.object,
  };

  onData = (data) => {
    const {events: existingEvents, historicEvents} = vehicleTrackerStore.get();
    vehicleTrackerStore.set({historicEvents: {...existingEvents}});
    const events = data.Records.reduce(
      (combined, record) => {
        const evt = record.dynamodb.NewImage;
        const speed = evt.gps.gps.speed;
        const speedLimit = evt?.address?.postedSpeedLimit || 0;
        const lat = Number(evt.latitude);
        const lng = Number(evt.longitude);
        const esn = evt.deviceEsn;
        const moving =
          speed > 0 ||
          (!!historicEvents?.[esn] &&
            (lat !== historicEvents[esn].lat ||
              lng !== historicEvents[esn].lng));
        const date = getUnixTime(parseISO(evt.createdAt));
        const event = {
          evt,
          speed,
          speeding: moving && speed > 0 && speedLimit > 0 && speed > speedLimit,
          moving,
          lat,
          lng,
          esn,
          date,
        };
        return date > (combined?.[esn]?.date || 0)
          ? {...combined, [esn]: event}
          : combined;
      },
      {...existingEvents}
    );
    const lastSocketEvent = new Date().getTime();
    vehicleTrackerStore.set({events, lastSocketEvent});
  };

  render() {
    return null;
  }
}

export default subscriptionHOC(VehicleEventTrackerContainer);
