const TEXT_VALUES = [
  'app_reservations_per_hour',
  'accidents_slack_channel',
  'city',
  'email',
  'garage_address1',
  'garage_address2',
  'garage_city',
  'garage_name',
  'garage_phone_number',
  'garage_state',
  'garage_vehicle_capacity',
  'garage_zipcode',
  'gas_slack_channel',
  'home_station',
  'markets_slack_channel',
  'minimum_deposit',
  'minimum_rental_rate',
  'name',
  'ops_slack_channel',
  'overview_url',
  'payments_slack_channel',
  'phone_number',
  'state',
  'street_address1',
  'street_address2',
  'tag',
  'tax_rate',
  'timezone_name',
  'toll_service',
  'weekly_safetyscore_budget',
  'zipcode',
  'registration_pickup_window',
  'reservation_deposit_required',
];

export default TEXT_VALUES;
