// css
import getExternalCSS from '../../../lib/getExternalCss.lib.print';

const print = ({domId, responsive}) => {
  const content = document.getElementById(domId).innerHTML;
  const head = [
    '<meta charset="utf-8" />',
    responsive &&
      '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, minimum-scale=1, viewport-fit=cover" />',
    '<link href="https://s3.amazonaws.com/sally-dashboard-assets/fonts/fonts.css" rel="stylesheet" data-react-helmet="true">',
    '<link href="https://fonts.googleapis.com/css?family=Pacifico" rel="stylesheet" data-react-helmet="true">',
    '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@7.1.96/css/materialdesignicons.min.css" data-react-helmet="true">',
  ]
    .filter((headLink) => !!headLink)
    .join('');
  const css = getExternalCSS();
  const html = `<html><head>${head}<style>${css}</style></head><body>${content}</body></html>`;
  const a = window.open('', '_blank');
  a.document.write(html);
  const close = () => {
    a.close();
  };

  setTimeout(() => {
    // a.document.getElementById('closePrintWindowButton').addEventListener(
    //   'click',
    //   () => {
    //     a.close();
    //   },
    //   false
    // );
    a.document.close();
    a.print();
    a.onafterprint = close;
  }, 500);
};

export default print;
