import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';



// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// auth actions
import {signIn} from '../../../redux/authorization/actions';

// auth api
import {auth} from '@matthahn/sally-fn';

// api constants
import API_URL from '../../../api/constants/url.const.api';

// auth api
import signInWithGoogleApi from '@matthahn/sally-fw/lib/auth/api/signInWithGoogle.api.auth';

// auth components
import AuthBg from '../../components/AuthBg/AuthBg';
import AuthorizationCard from '../../components/AuthorizationCard/AuthorizationCard';
import SSO from '../../components/SSO/SSO';

// auth lib
import getAuthEndpoints from '../../lib/getAuthEndpoints.lib.auth';

// dashboard
import routeDashboard from '../../../components/pages/DashboardPage/route';

// error parser
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// jwt
import jwt from 'jsonwebtoken';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

class SSOContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    traditional: PropTypes.bool,
    history: PropTypes.object,
  };

  static defaultProps = {
    traditional: false,
  };

  state = {
    loading: false,
    username: '',
    password: '',
    loginWithCredentials: false,
    tokenId: null,
  };

  componentDidMount() {
    const {traditional} = this.props;
    this.setState({loginWithCredentials: traditional});
  }

  start = () => {
    this.setState({loading: true});
  };

  success = async ({authToken = null, ...data} = {}) => {
    const tokenId = data?.tokenId || this.state.tokenId;
    this.setState({loading: true, tokenId});
    try {
      const {token} = await signInWithGoogleApi({
        token: tokenId,
        apiUrl: API_URL(),
        authToken,
      });
      const tokens = await Promise.all(
        getAuthEndpoints().map((app) =>
          this.getAppToken({...app, tokenId, authToken})
        )
      );
      await this.getProfileAndSignIn({token, tokens});
    } catch (error) {
      const {message} = parseError(error);
      this.prepareForLoginWithCredentials(message);
    }
  };

  getAppToken = async ({app, url, tokenId, authToken}) => {
    try {
      const response = await signInWithGoogleApi({
        token: tokenId,
        apiUrl: url,
        authToken,
      });
      return {app, token: response.token};
    } catch (error) {
      return {app, token: null};
    }
  };

  fail = ({error} = {}) => {
    const {loading} = this.state;
    if (!loading) return;
    this.prepareForLoginWithCredentials(error);
  };

  prepareForLoginWithCredentials = (error) => {
    if (error === 'popup_closed_by_user')
      return this.setState({loading: false});
    alert.warning(`${error}. Login with your credentials first.`);
    this.setState({loading: false, loginWithCredentials: true});
  };

  signIn = async () => {
    const {traditional} = this.props;
    const {username, password, loading} = this.state;
    if (loading) return;

    if (!username.trim().length) return alert.info('Insert a username');
    if (!password.trim().length) return alert.info('Insert a password');

    this.setState({loading: true});

    try {
      const {token} = await auth.api.signin({
        username,
        password,
      });
      await (traditional
        ? this.getProfileAndSignIn({token})
        : this.success({authToken: token}));
    } catch (error) {
      this.setState({loading: false, password: ''});
      alert.warning('Wrong username or password');
    }
  };

  getProfileAndSignIn = async ({token, tokens = []}) => {
    const {dispatch, traditional, history} = this.props;
    const {user_id} = jwt.decode(token);
    const userData = await auth.api.getUserInfo(user_id, token);
    const appTokens = [...tokens]
      .filter((appToken) => !!appToken?.token)
      .reduce(
        (combined, current) => ({...combined, [current.app]: current.token}),
        {}
      );
    dispatch(signIn(token, userData, appTokens));
    alert.success(`Hello ${userData.username}`);
    if (traditional) history.replace(routeDashboard());
  };


  change = (key) => (value) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  render() {
    const {loading, username, password, loginWithCredentials} = this.state;
    return (
      <Fragment>
        <AuthBg />
        <AuthorizationCard>
          <SSO
            clientId="1044624392731-pmdo6ji0it07peeg9d6fr50erq89qc07.apps.googleusercontent.com"
            loading={loading}
            onSuccess={this.success}
            onFailure={this.fail}
            onStart={this.start}
            onSignIn={this.signIn}
            username={username}
            password={password}
            loginWithCredentials={loginWithCredentials}
            onChange={this.change}
          />
        </AuthorizationCard>
      </Fragment>
    );
  }
}

export default withRouter(connect()(SSOContainer));
