import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

// constants
const SIZE = '50%';

const PrintImageWrap = styled.div`
  width: ${SIZE};
  min-width: ${SIZE};
  max-width: ${SIZE};
  height: 500px;
  position: relative;
  padding: 10px;
  border: 1px solid ${grey};
`;

export default PrintImageWrap;
