import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Select} from '@matthahn/sally-ui';
import {Aside} from '../../molecules/Aside';

import Header from './components/Header.Profile';
import Actions from './components/Actions.Profile';
import BranchContainer from './components/BranchContainer';

const Profile = ({
  actions = [],
  branch,
  branches,
  onBranch,
  onClose,
  username,
  visible,
}) => (
  <div>
    <Aside visible={visible} onClose={onClose}>
      <Header username={username} onClose={onClose} />
      <BranchContainer>
        <Select
          value={`${branch}`}
          options={[...branches].filter(({decommissioned}) => !decommissioned).map(({id, name}) => ({
            value: `${id}`,
            label: name,
          }))}
          onChange={onBranch}
          notFilterable
        >
          Default Branch
        </Select>
      </BranchContainer>
      <Actions actions={actions} />
    </Aside>
  </div>
);

Profile.propTypes = {
  actions: PropTypes.array,
  branch: PropTypes.number,
  branches: PropTypes.array,
  onBranch: PropTypes.func,
  onClose: PropTypes.func,
  username: PropTypes.string,
  visible: PropTypes.bool,
};

export default Profile;
