import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// Components
import {Icon} from '@matthahn/sally-ui';
import Arrow from './components/Arrow';
import Content from './components/Content';
import Date from './components/Date';
import Image from './components/Image';
import Label from './components/Label';
import Slide from './components/Slide';
import Wrapper from './components/Wrapper';
import PrintContainer from './components/PrintContainer';
import PrintImageWrap from './components/PrintImageWrap';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Lib
import displayTimeZoneSpecificDate from '../../../libs/displayTimeZoneSpecificDate';

// Types
import {dateTime} from '@matthahn/sally-fn/lib/types';

// Settings
const settings = {
  arrows: false,
  dots: false,
  centerMode: true,
  centerPadding: '0px',
  infinite: true,
  speed: 250,
  slidesToShow: 1,
  slidesToScroll: 1,
};

class ImageSlider extends Component {
  static propTypes = {
    branch: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    height: PropTypes.string,
    images: PropTypes.array,
    print: PropTypes.bool,
  };

  static defaultProps = {
    height: '500px',
  };

  slider = null;

  next = () => {
    if (!this.slider) return;
    this.slider.slickNext();
  };

  previous = () => {
    if (!this.slider) return;
    this.slider.slickPrev();
  };

  sliderRef = (slider) => {
    this.slider = slider;
  };

  render() {
    const {branch, height, images, print} = this.props;
    return print ? (
      <PrintContainer>
        {images.map((picture) => (
          <PrintImageWrap key={picture.key}>
            <Image src={picture.image} />
            {!!picture?.label && <Label>{picture.label}</Label>}
            {!!picture?.date && (
              <Date>
                {displayTimeZoneSpecificDate({
                  date: picture.date,
                  type: dateTime,
                  branch,
                })}
              </Date>
            )}
          </PrintImageWrap>
        ))}
      </PrintContainer>
    ) : (
      <Content>
        <Wrapper>
          <Slider {...settings} ref={this.sliderRef}>
            {images.map((picture) => (
              <Slide key={picture.key} height={height}>
                <Image src={picture.image} />
                {!!picture?.label && <Label>{picture.label}</Label>}
                {!!picture?.date && (
                  <Date>
                    {displayTimeZoneSpecificDate({
                      date: picture.date,
                      type: dateTime,
                      branch,
                    })}
                  </Date>
                )}
              </Slide>
            ))}
          </Slider>
          <Arrow left onClick={this.previous}>
            <Icon icon="left" />
          </Arrow>
          <Arrow onClick={this.next}>
            <Icon icon="right" />
          </Arrow>
        </Wrapper>
      </Content>
    );
  }
}

export default ImageSlider;
