// driver lib
// import isDriverOnPaymentPlan from './isOnPaymentPlan.lib.driver';

const showAppPaymentExtensionOption = ({driver, paymentPlanBalance}) => true;
// const showAppPaymentExtensionOption = ({driver, paymentPlanBalance}) =>
//   driver?.disable_app_payment_extensions ||
//   (!!paymentPlanBalance?.payment_plan_balance &&
//     !isDriverOnPaymentPlan(driver));

export default showAppPaymentExtensionOption;
