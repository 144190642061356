import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Google
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

// local components
import {Button, Input, Form} from '@matthahn/sally-ui';
import FormContainer from './components/FormContainer';
import Logo from './components/Logo';
import Submit from './components/Submit';


const SSO = ({
  clientId,
  loading,
  loginWithCredentials,
  onChange,
  onFailure,
  onSignIn,
  onStart,
  onSuccess,
  password,
  username,
}) => (
  <Fragment>
    <Logo color="white" />
    {loginWithCredentials ? (
      <Form onSubmit={onSignIn}>
        <FormContainer>
          <Input
            value={username}
            onChange={onChange('username')}
            disabled={loading}
            placeholder="Username"
            size="xl"
            autoCapitalize="none"
            transparent
            moreRadius
          />
          <Input
            type="password"
            value={password}
            onChange={onChange('password')}
            disabled={loading}
            placeholder="Password"
            size="xl"
            autoCapitalize="none"
            transparent
            moreRadius
          />
          <Submit>
            <Button
              loading={loading}
              size="xl"
              icon="login"
              theme="black"
              noRadius
              block
              shadow
            >
              Sign In
            </Button>
          </Submit>
        </FormContainer>
      </Form>
    ) : (

      <GoogleOAuthProvider clientId={clientId} nonce={'gasdfdsf'}>
        <GoogleLogin
          width="250"
          text="signin"
          logo_alignment="center"
          onSuccess={credentialResponse => {
            onSuccess({tokenId:credentialResponse['credential']});
          }}
          onError={() => {
            console.log('Login Failed');
            onFailure();
          }}
        />
        </GoogleOAuthProvider>

      // Matej i'm leaving this here so you can make a custom button if you'd like. see example below:
      //////////////
      // import { useGoogleLogin } from '@react-oauth/google';
      // const login = useGoogleLogin({
      //   onSuccess: tokenResponse => console.log(tokenResponse),
      // });
      // <GoogleOAuthProvider clientId={clientId} nonce={'gasdfdsf'}>
      // <MyCustomButton onClick={() => login()}>Sign in with Google</MyCustomButton>;
      // /GoogleOAuthProvider>
      //////////////





      /// OLD LOG IN - will not work
      // <GoogleLogin
      //   clientId={clientId}
      //   buttonText="Login"
      //   onSuccess={onSuccess}
      //   onFailure={onFailure}
      //   cookiePolicy={'single_host_origin'}
      //   render={({onClick, ...renderProps}) => (
      //     <Button
      //       {...renderProps}
      //       onClick={() => {
      //         onStart();
      //         onClick();
      //       }}
      //       loading={loading}
      //       size="xl"
      //       icon="google"
      //       theme="black"
      //       noRadius
      //       block
      //       shadow
      //     >
      //       Sign In
      //     </Button>
      //   )}
      // />
    )}
  </Fragment>
);

SSO.propTypes = {
  clientId: PropTypes.string,
  loading: PropTypes.bool,
  loginWithCredentials: PropTypes.bool,
  onChange: PropTypes.func,
  onFailure: PropTypes.func,
  onSignIn: PropTypes.func,
  onStart: PropTypes.func,
  onSuccess: PropTypes.func,
  password: PropTypes.string,
  username: PropTypes.string,
};

export default SSO;
